/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard 
	Version: 2.0
	Author: 
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

*{
	font-family: "Inter",sans-serif;
}
.nav-link{
	color: rgba(250, 56, 56, 0.616) !important
}

.nav-link:hover{
	color: rgb(247, 48, 48)
}
.btn-outline-primary{
	color: gray;
	border-color: gray !important;
}
svg.ficon:hover{
	color: rgb(247, 48, 48) !important
}
.text-primary{
	color: rgba(250, 56, 56, 0.616)  !important
}
.text-primary:hover{
	color: rgb(247, 48, 48) !important
}

h1{
	color: #000000
}
.expanded span.brand-logo {
    /* background: red; */
    transition: 0.2s;
    translate: 100%;
    scale: 2;
}
.menu-open span.brand-logo {
    /* background: red; */
    translate: 100%;
    scale: 2;
    transition: 0.2s;
}


// take order

.rst-table{
	border-radius: 10px;
	// aspect-ratio:1 / 1;
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.5rem;
	width: 10.825rem;
	height:  9.75rem;
	font-family: 'Inter';
	// font-style: normal;
	font-weight: 400;
	font-size: 1rem;
	line-height: 2.1875rem; // 35rem
}
.bookedtable{
	background:linear-gradient(166.45deg, #F2473F -14.38%, #FF6B34 105%);
	color: var(--text-gray-100, #EDF5F9);
	cursor: pointer;
}

.unbookedtable{
	border: 2px dashed #BFD1E3;
	color: #1A264E;
	cursor: pointer;
}
.clubtable{
	background: #FFEBE6;
}
.table-no{
	display:flex;
	justify-content: space-between;
}
.table-no .tn{
font-size: 1.5rem;
font-family: Inter;
font-weight: 700;
line-height: 2.176rem;
}
.club{
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 2.1875rem;
	margin: 0;
	color: #1A264E;
}

.table-bottom{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center
}

.table-bottom ul {
	margin: 0;
}
@media ( max-width: 500px){
	.rst-table{
		font-weight: 400;
		font-size: 0.75rem;
		line-height: 1.1875;
	}
	.table-no .tn{
		font-weight: 600;
		font-size: 1rem;
		line-height: 1.125rem;
	}
	.club{
		font-weight: 400;
		font-size: 0.75rem;
		line-height: 1.1875;
	}
	.rst-table p{
		margin: 0;
	}
}

// ================ Data Table====================
// .rdt_Table {
// 	min-height: 200px !important;
// }

.rdt_TableHeadRow div{
	// text-overflow:clip;
	white-space: break-spaces;
	// text-align: left;
	padding: 0 0.3rem;
	// background: red !important;
}

.table-header-title{
	color: #1A264E;
	font-size: 1.5rem;
	white-space: nowrap;
	font-weight: 700;
	text-align: center;
	margin: 0;
	line-height: 1.3;
}
.rdt_TableCol{
	gap: 8rem;
}
.prev,
.next{
	cursor:pointer
}

// ================ Data Table====================
// .rdt_Table {
// 	min-height: 200px !important;
// }

.rdt_TableHeadRow div {
	// text-overflow:clip;
	white-space: break-spaces;
	// text-align: left;
	padding: 0 0.3rem;
	// background: red !important;
}

.table-header-title {
	color: #1A264E;
	font-size: 1.3rem;
	white-space: nowrap;
	font-weight: 700;
	text-align: center;
	margin: 0;
}

.rdt_TableCol {
	gap: 8rem;
}

.prev,
.next {
	cursor: pointer
}

// ======================================
// ================ ****************e====================
.filled-star{
	fill: #FFB956;
	border: transparent;
}

.unfilled-star{
	fill: #E7ECF0;
	border: transparent;
}

::-webkit-scrollbar{
	width: 15px;
}


// Styling table
.table-bottom{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center
}
.table-bottom ul {
	margin: 0;
}
.sc-dmRaPn {
	height: 100% !important;
	background: #ffffff;
}

.sc-dmRaPn::-webkit-scrollbar {
	width: 0;
}


@keyframes rot {
	from{
		rotate: (0deg);
	}
	to{
		rotate:( 360deg);
	}
}

.order-item-addons-active {
	background-image: linear-gradient(180.63deg, #5E3225 3.63%, #832A0E 93.58%);
	// text-fill-color: transparent;	
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

}

.order-item-addons-active svg {
	stroke: #832A0E
}

.cart {
	height: inherit;

	margin: 0 5px
}

.cart .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cart .item-name {
	color: var(--text-gray-900, #1A264E);
	font-size: 0.875rem;
	font-family: Inter;
	font-weight: 600;
	line-height: 1rem;
	margin-bottom: 0.8rem;
}

.cart .varient-name {
	color: #292D32;
	font-size: 0.75rem;
	font-family: Inter;
	font-weight: 600;
	line-height: 1.3125rem;
	margin: 0;
}

.cart .addon-label {
	color: var(--text-gray-600, #5B6D8E);
	text-align: center;
	font-size: 0.75rem;
	font-family: Inter;
	font-weight: 500;
	line-height: 1.3125rem;
	border-radius: 9999px;
	background: #F5F8FA;
	padding: 0.125rem 0.375rem;
}

// . {
//   color: #01a879;
//   text-align: left;
//   font: 600 14px/16px "Inter", sans-serif;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// }
.variants-addons {
	display: flex;
	flex-direction: row;
	gap: 4px;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	color: #01a879;
	text-align: center;
	font: 600 12px "Inter", sans-serif;
	position: relative;
	cursor: pointer;
}

.menu-item-list {
	padding: 0 1rem;

	.category-title {
		font-weight: 700;
		font-size: 1.0625rem;
		color: rgb(7, 9, 17);
		padding: 1rem;
		text-transform: capitalize;
	}

	.menu-item-container {
		display: grid;
		gap: 1rem;
		box-shadow: none;
	}

	.menu-item-container2 {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;
		box-shadow: none;
	}

	.menu-item-container3 {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 1rem;
		box-shadow: none;
	}

	.menu-item-card {

		box-shadow: none;
		border-radius: 8px;
		border: 1px solid var(--text-gray-200, #E7ECF0);
		margin: 0;
	}
}

/* removing default style of button */

.search button {
	border: none;
	background: none;
	color: #8b8ba7;
}

/* styling of whole input container */
.search {
	--timing: 0.3s;
	--width-of-input: 55%;
	--height-of-input: 40px;
	--border-height: 2px;
	--input-bg: #fff;
	--border-color: #2f2ee9;
	// --border-radius: 30px;
	--after-border-radius: 1px;
	position: relative;
	width: var(--width-of-input);
	height: var(--height-of-input);
	display: flex;
	align-items: center;
	padding-inline: 0.8em;
	// border-radius: var(--border-radius);
	transition: border-radius 0.5s ease;
	background: var(--input-bg, #fff);
	box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
}

@media (max-width:720px) {
	.search{
		width: 100% !important;
	}
}
/* styling of Input */
.input {
	font-size: 0.9rem;
	background-color: transparent;
	width: 100%;
	height: 100%;
	padding-inline: 0.5em;
	padding-block: 0.7em;
	border: none;
}

/* styling of animated border */
.search:before {
	content: "";
	position: absolute;
	background: var(--border-color);
	transform: scaleX(0);
	transform-origin: center;
	width: 100%;
	height: var(--border-height);
	left: 0;
	bottom: 0;
	border-radius: 1px;
	transition: transform var(--timing) ease;
}

/* Hover on Input */
.search:focus-within {
	border-radius: var(--after-border-radius);
}

input:focus {
	outline: none;
}

/* here is code of animated border */
.form:focus-within:before {
	transform: scale(1);
}

/* styling of close button */
/* == you can click the close button to remove text == */
.reset {
	border: none;
	background: none;
	opacity: 0;
	visibility: hidden;
}

/* close button shown when typing */
input:not(:placeholder-shown)~.reset {
	opacity: 1;
	visibility: visible;
}

/* sizing svg icons */
.search svg {
	width: 17px;
	margin-top: 3px;
}

// Styling table
.table-bottom{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center
}
.table-bottom ul {
	margin: 0;
}
.sc-dmRaPn {
	height: 100% !important;
	background: #ffffff;
}

// .sc-dmRaPn::-webkit-scrollbar {
// 	width: 0;
// }
::-webkit-scrollbar {
	width: 0.5em;
	height: 0.5rem
}

::-webkit-scrollbar-track {
	background: rgb(240, 239, 239);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: rgba(165, 161, 161, 0.479);
	border-radius: 50px;

}

::-webkit-scrollbar-thumb:hover {
	background: #ff6a348a;
}


.order-btn-group {
	display: flex;
	flex-wrap: nowrap;
	flex-grow: 1
}

.order-btn-group .btn-secondary {
	font-weight: 500;
	font-size: 11px;
	line-height: 13px;
	text-align: center;
	letter-spacing: 0.366667px;
	padding: 9px 10px;
	border: none;
	background: linear-gradient(166.45deg, rgba(242, 71, 63, 0.1) -14.38%, rgba(255, 107, 52, 0.1) 105%) !important;
	color: #702913 !important;
}

.order-btn-group .btn-secondary.active {
	background-color: #702913 !important;
	color: #ffffff !important;
}

.order-btn-group .btn-secondary.active:focus {
	background-color: #702913 !important;
}
